<template>
  <v-container class="pa-0" fluid>
    <div id="navMobile">
      <a href="/">
        <v-img max-height="60" max-width="220" src="@/assets/company_logo_small.jpg"/>
      </a>
      <img src="@/assets/images/iso_menu.png" height="35">
    </div>
    <div style="padding-top: 55px;"></div>
    <v-img v-if="$route.name == 'Home' || $route.name == 'Aboutus'"
           class="welcome menu-mobile"
           :src="welcome_images[welcome_index]"
           cover/>
    <div class="menu-mobile">
      <Slide class="menu-mobile" right noOverlay :closeOnNavigation="true">
        <router-link :to="{ name: 'Home' }">
          <v-img max-width="260" src="@/assets/company_logo_small.jpg"/>
        </router-link>
        <div class="px-5 pb-0">
          <v-btn v-for="(lang, index) in langs"
                 :key="index"
                 :class="($i18n.locale === lang.value)? 'green--text text--darken-4' : 'black--text'"
                 color="transparent"
                 depressed
                 small
                 text
                 raised
                 tile
                 @click="changeLang(lang.value)">{{ lang.name }}
            <span v-if="index < (langs.length -1)" class="pl-5 black--text">|</span></v-btn>
        </div>
        <a class="text-decoration-none black--text font-weight-bold pt-1 pb-1" v-if="!user">
          <v-btn class="font-weight-bold pr-5 white" large elevation='0' @click="$refs.login.open()">{{ $t('login_or_reg') }}</v-btn>
        </a>

        <v-menu rounded offset-y v-else>
          <template v-slot:activator="{ attrs, on }">
            <v-btn class="pr-5 text--black" text v-bind="attrs" v-on="on" x-large elevation='0'>
              <v-icon color="black">mdi-account-circle</v-icon>
              <br>
              <span style="max-width: 100px; overflow: hidden; color: black;">{{ $t('user') }}</span>
            </v-btn>
          </template>

          <v-list>
            <!--              <v-list-item link>-->
            <!--                <v-list-item-title>修改密碼</v-list-item-title>-->
            <!--              </v-list-item>-->
            <v-list-item small text>
              <v-list-item-title style="font-size: 13px;">{{ $t('user') }}: {{ user.name }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="open('cart')">
              <v-list-item-title>我的購物車</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="open('order')">
              <v-list-item-title>我的訂單</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="storeLogout">登出</v-list-item-title>
            </v-list-item>


          </v-list>
        </v-menu>

        <a class="text-decoration-none black--text font-weight-bold pt-1 pb-1">
          <v-btn class="font-weight-bold pr-5 white" large elevation='0' @click="open('cart')">{{ $t('cart_menu') }}</v-btn>
        </a>

        <router-link v-for="item in header" :key="item.page" :to="item.link" class="text-decoration-none black--text font-weight-bold pt-1 pb-1">
          <v-btn class="pr-5 white" large elevation='0'>
            {{ $t(item.name) }}
          </v-btn>
        </router-link>
        <div style="text-align: right">
          <img src="@/assets/images/iso.png" height="70">
        </div>
      </Slide>
    </div>

    <v-row class="ma-0 menu-desktop">
      <v-col v-if="$route.name == 'Home' || $route.name == 'Aboutus'" cols="12" class="relative" style="padding: 0;">
        <div class="mt-6 justify-end d-flex ml-auto text-right align-end topheader flex-wrap"
             style="position: absolute; right: 0; z-index: 1; background-color: rgba(0,0,0,0.4);top: 80px;"
             color="transparent" flat absolute>
          <span color="transparent" class="white--text ml-8" v-for="(info, key) in slider" :key="key">
            <v-icon dark class="pr-2">mdi-{{ info.icon }}</v-icon>  {{ info.number }}
          </span>
          <div class="px-5">
            <v-btn v-for="(lang, index) in langs"
                   :key="index"
                   :class="($i18n.locale === lang.value)? 'green--text text--darken-1' : 'white--text'"
                   color="transparent"
                   depressed
                   large
                   text
                   raised
                   tile
                   @click="changeLang(lang.value)"
            >{{ lang.name }} <span v-if="index < (langs.length -1)" class="pl-5 white--text">|</span></v-btn>
          </div>
          <div class="col-12 justify-start d-flex align-center">
            <div class="d-flex justify-start d-flex align-center">
              <v-img src="@/assets/images/wa_white.png" height="30" width=30 class="ml-7 mr-2"></v-img>
              <a href="https://wa.me/+85254013775" target="_blank">5401 3775</a>
            </div>
            <div class="d-flex justify-start d-flex align-center">
              <v-img src="@/assets/images/wa_white.png" height="30" width=30 class="ml-8 mr-2"></v-img>
              <a class="ml-1" href="https://wa.me/+85263604510" target="_blank">6360 4510</a>
            </div>
          </div>
        </div>
        <v-img :src="welcome_images[welcome_index]"
               height="100vh"
               class="welcome"
               position="top right"
               cover/>
        <v-img v-for="img in welcome_images" :src="img" height="1" width="1" style="opacity: 0; position: absolute" v-bind:key="img"/>
        <v-img src="@/assets/iso_menu.png"
               style="position: absolute; right: 50px; bottom: 50px;"
               max-width="300"
               contain/>
      </v-col>

      <v-col class="pa-0">
        <v-app-bar id="nav" flat color="white">
          <v-toolbar-title>
            <div class="d-flex">
              <a href="/">
                <v-img max-height="60" max-width="350" src="@/assets/company_logo_small_desktop.jpg"/>
              </a>
              <img src="@/assets/images/iso_menu.png" height="60">
            </div>
          </v-toolbar-title>
          <v-spacer/>
          <router-link v-for="item in header" :key="item.page" :to="item.link" class="text-decoration-none black--text ">
            <v-btn :class="currentPage(item.page)" class="font-weight-bold" x-large elevation='0'>
              {{ $t(item.name) }}
            </v-btn>
          </router-link>
          <a class="text-decoration-none black--text">
            <v-btn class="font-weight-bold pr-5 white" x-large elevation='0' @click="open('cart')">{{ $t('cart_menu') }}</v-btn>
          </a>
          <a class="text-decoration-none black--text" v-if="!user">
            <v-btn class="font-weight-bold pr-5 white" x-large elevation='0' @click="$refs.login.open()">{{ $t('login_or_reg') }}</v-btn>
          </a>

          <v-menu rounded offset-y v-else>
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="pr-5 white" v-bind="attrs" v-on="on" x-large elevation='0'>
                <v-icon dark>mdi-account-circle</v-icon>
                <br>
                <span style="max-width: 100px; overflow: hidden;">{{ $t('user') }}</span>
              </v-btn>
            </template>

            <v-list>
              <!--              <v-list-item link>-->
              <!--                <v-list-item-title>修改密碼</v-list-item-title>-->
              <!--              </v-list-item>-->
              <v-list-item small text>
                <v-list-item-title style="font-size: 13px;">{{ $t('user') }}: {{ user.name }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="chPW">
                <v-list-item-title>{{ $t('change_passsword') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="open('cart')">
                <v-list-item-title>{{ $t('my_cart') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link @click="open('order')">
                <v-list-item-title>{{ $t('my_order') }}</v-list-item-title>
              </v-list-item>
              <v-list-item link>
                <v-list-item-title @click="storeLogout">{{ $t('logout') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-app-bar>
      </v-col>
    </v-row>
    <LoginAndReg ref="login"></LoginAndReg>
    <ChangePassword ref="chPassword"></ChangePassword>
  </v-container>
</template>

<script>
import {mapState}     from 'vuex';
import {Slide}        from 'vue-burger-menu';
import $              from 'jquery'
import home_welcome   from '@/assets/images/home_welcome_1.jpg'
import home_welcome2  from '@/assets/images/home_welcome2_2.jpg'
import home_welcome3  from '@/assets/images/home_welcome3_3.jpg'
import home_welcome4  from '@/assets/images/home_welcome4_2.jpg'
// import home_welcome5 from '@/assets/images/home_welcome5.jpg'
// import home_welcome6 from '@/assets/images/home_welcome6.jpg'
import LoginAndReg    from './LoginAndReg'
import ChangePassword from './ChangePassword'
import {mapMutations} from "vuex"

export default {
  created () {
    let new_index = 0
    setInterval(() => {
      this.welcome_index = new_index
      new_index += 1
      if (new_index > this.welcome_images.length - 1) new_index = 0
    }, 5000)
  },
  computed   : {
    ...mapState(['languages', 'user'])
  },
  components : {
    Slide,
    LoginAndReg,
    ChangePassword
  },
  props      : {
    showMainSlider : {
      type : Function
    }
  },
  mounted () {
    let t = this

    // t.currentRouteName()

    // $(window).scroll(function () {
    //   try {
    //     let $el = $('.v-app-bar.v-app-bar--is-scrolled')
    //     if (!t.menu_y) {
    //       t.menu_y = $el.offset().top
    //     }
    //
    //     if (t.$route.name === 'Home') {
    //       if ($(this).scrollTop() >= 550) {
    //         $el.addClass('navfixed')
    //       }
    //     }
    //   } catch (e) {
    //     // console.log(e)
    //   }
    // });

  },
  methods : {
    ...mapMutations(['storeLogout']),

    chPW () {
      this.$refs.chPassword.open()
    },

    open (tab) {
      if (!this.user) return this.$refs.login.open()
      window.sanfungCart.open(tab)
    },
    currentRouteName () {
      this.currentroute = this.$route.name
      if (this.$route.name !== 'Home') {
        this.navfixed = 'navfixed'
      }
    },
    changeLang (newLang) {
      this.$store.commit('setLang', newLang);
      this.$i18n.locale = newLang;
      localStorage.setItem('footmark-lang', newLang);
    },
    currentPage (currentPage) {
      let value = 'pr-5 white';

      let productPageArr = ['Products', 'ProductCategory']
      let machinesPageArr = ['Machines', 'MachinesDetail', 'MachinesList']

      if (this.$route.name === currentPage) {
        value = 'pr-5 white green--text text--darken-4'
      } else if (productPageArr.includes(currentPage) && productPageArr.includes(this.$route.name)) {
        value = 'pr-5 white green--text text--darken-4'
      } else if (machinesPageArr.includes(currentPage) && machinesPageArr.includes(this.$route.name)) {
        value = 'pr-5 white green--text text--darken-4'
      }

      return value
    }
  },
  data    : () => ({
    menu_y         : 0,
    welcome_images : [home_welcome, home_welcome2, home_welcome3, home_welcome4],
    welcome_index  : 0,
    header         : [
      {
        'page' : 'ProductCategory',
        'name' : 'page_name.product',
        'link' : '/products'
      },
      /*{
       'page' : 'ProductCategory',
       'name' : 'page_name.product',
       'link' : '/productcategory'
       },*/
      {
        'page' : 'Aboutus',
        'name' : 'page_name.aboutus',
        'link' : '/aboutus'
      },
      {
        'page' : 'Video',
        'name' : 'page_name.video',
        'link' : '/video'
      },
      {
        'page' : 'Machines',
        'name' : 'page_name.machines',
        'link' : '/machines'
      },
      {
        'page' : 'Contectus',
        'name' : 'page_name.contectus',
        'link' : '/contactus'
      },
      {
        'page' : 'Standard',
        'name' : 'page_name.standard',
        'link' : '/standard'
      },
      {
        'page' : 'Downloads',
        'name' : 'page_name.downloads',
        'link' : '/downloads'
      },
    ],
    slider         : [
      { icon : 'phone', number : '2479 0383' },
      // { icon : 'phone', number : '2479 7981 ' },
      { icon : 'fax', number : '2474 3688' },
      // { icon : 'fax', number : '2470 0772' },
    ],
    langs          : [
      { name : '繁', value : 'tc' },
      { name : '簡', value : 'sc' },
      { name : 'Eng', value : 'eng' },
    ],
    navfixed       : '',
    currentroute   : ''
  }),
}
</script>

<style scoped lang="less">
.topheader {
  max-width: 680px;

  span, a {
    font-size: 25px;
    text-decoration: none;
    color: white;
  }
}
</style>
